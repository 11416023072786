import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-grid--totals" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "count-label" }
const _hoisted_4 = { class: "patients-list" }
const _hoisted_5 = { class: "patients-list-item" }
const _hoisted_6 = { class: "patients-list-item-header" }
const _hoisted_7 = { class: "patients-item-header-left" }
const _hoisted_8 = { class: "chat-list-item-footer" }
const _hoisted_9 = { class: "chat-list-item-date" }
const _hoisted_10 = {
  key: 0,
  class: "greeting-container"
}
const _hoisted_11 = { class: "greetings-paginator-container" }

import dateFormat from '@/helpers/DateFormat.helper';
import { ChatFormDto } from '@/models/Chat';
import Patient from '@/models/Patient';
import router from '@/router';
import { chatService } from '@/services/ChatService';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { useProfileStore } from '@/store/profile.module';
import moment from 'moment';
import { PageState } from 'primevue/paginator';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import EmptyState from '../EmptyState.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewPatientsGreeting',
  props: {
  professionalId: {
    type: String,
  },
},
  setup(__props) {

const storeProfile = useProfileStore();
const { t } = useI18n();
const toast = useToast();
const isAdminUser = computed(() => storeProfile.isAdmin);

const patients = ref<Patient[]>([]);

const patientsToShow = ref<Patient[]>([]);

const props = __props;

watch(
  () => props.professionalId,
  () => loadNewPatients(),
);

onMounted(async () => {
  await loadNewPatients();
});

async function loadNewPatients() {
  const result = await patientService.findDashboardPatients(props.professionalId);
  if (!(result instanceof ServiceError)) {
    patients.value = result.sort(
      (a, b) => dateFormat.getTimeFromDateString(b.creationDate) - dateFormat.getTimeFromDateString(a.creationDate),
    );
    patientsToShow.value = result.slice(0, 10);
  }
}

const onPageChange = (event: PageState) => {
  console.log(event);
  patientsToShow.value = patients.value.slice(event.first, event.first + event.rows);
};

const getLastAgo = (lastActivity: string) => {
  const currentDate = moment();
  const lastActivityDate = dateFormat.utcToUserTimezone(lastActivity);

  const duration = moment.duration(currentDate.diff(lastActivityDate));

  if (duration.asMinutes() < 60) {
    return `${t('patient.last')} ${duration.asMinutes().toFixed(0)} ${t('patient.minutesAgo')} `;
  } else if (duration.asHours() < 24) {
    return `${t('patient.last')} ${duration.asHours().toFixed(0)} ${t('patient.hoursAgo')} `;
  } else {
    return `${t('patient.last')} ${duration.asDays().toFixed(0)} ${t('patient.daysAgo')} `;
  }
};

const greet = async (patient: Patient) => {
  const dto = new ChatFormDto(patient.userId);
  const result = await chatService.createChat(dto);
  if (result instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorCreatingChat')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  } else {
    router.push('/chats?chatId=' + result.id);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (patients.value && patients.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('dashboard.newPatients')) + " ", 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(`(${patients.value.length})`), 1)
          ]),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(patientsToShow.value, (patient, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "patient-element"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h1", null, _toDisplayString(patient.name + ' ' + patient.surname), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", null, _toDisplayString(patient.email), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", null, _toDisplayString(getLastAgo(patient.creationDate)), 1)
                  ])
                ]),
                (!isAdminUser.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_Button, {
                        class: "p-button-link",
                        disabled: patient.hasAnyChat,
                        onClick: ($event: any) => (greet(patient))
                      }, {
                        default: _withCtx(() => [
                          _cache[0] || (_cache[0] = _createElementVNode("i", {
                            class: "icon-message_plus",
                            "aria-hidden": "true"
                          }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('dashboard.greet')), 1)
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_Paginator, {
              rows: 10,
              totalRecords: patients.value.length,
              pageLinkSize: 3,
              class: "greetings-paginator",
              template: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
              onPage: onPageChange
            }, null, 8, ["totalRecords"])
          ])
        ]))
      : (_openBlock(), _createBlock(EmptyState, {
          key: 1,
          heading: _ctx.$t('dashboard.empty-new-patients-title'),
          text: _ctx.$t('dashboard.empty-new-patients-message'),
          secondary: false,
          icon: "icon-user_plus"
        }, null, 8, ["heading", "text"]))
  ]))
}
}

})